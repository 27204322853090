import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'app/core/service/auth/auth.service';
import { PollPostService } from 'app/modules/community/_services/poll-post.service';
import { ToastrService } from 'ngx-toastr';
import { ContactUsService } from './_service/contact-us.service';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {


  public sendForm: FormGroup;
  finalerror: any = [];
  spinnersend = false;
  isLoadingPollPost$;
  public submittedsendform = false;
  constructor(public activeModal: NgbActiveModal, private fb: FormBuilder, private pollPostService: PollPostService,private contactUsService : ContactUsService , private toaster: ToastrService,
    private authService : AuthService) { }

  ngOnInit(): void {
    
    this.isLoadingPollPost$ = this.pollPostService.isLoadingPollPost$;
    this.sendForm = this.fb.group({
      email: [this.authService.currentUserValue.email, Validators.required],
      subject: ['', Validators.required],
      message : ['',Validators.required]
    })


  }

  send() {
    try {

      this.submittedsendform = true;
      if (this.sendForm.valid) {
        const formvalue: any = this.sendForm.value;
        this.contactUsService.SendMailUser(formvalue).subscribe(res => {
          this.toaster.success("Email has been successfully sent!", "Success", {
            positionClass: 'toast-bottom-right',
          })
          this.activeModal.close();
        }, err => {

        })
      }

    } catch (err) {

    }
  }

}
