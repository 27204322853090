import {
  Component, OnInit, ViewChild, OnDestroy,
  ElementRef, AfterViewInit, ChangeDetectorRef, HostListener
} from "@angular/core";
import { ROUTES } from './vertical-menu-routes.config';
import { HROUTES } from '../horizontal-menu/navigation-routes.config';

import { Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { customAnimations } from "../animations/custom-animations";
import { DeviceDetectorService } from 'ngx-device-detector';
import { ConfigService } from '../services/config.service';
import { Subscription } from 'rxjs';
import { LayoutService } from '../services/layout.service';
import { AuthService } from "app/core/service/auth/auth.service";
import { InquiryService } from "app/modules/inquiry-list/_services/inquiry.service";
import { PollPostService } from "app/modules/community/_services/poll-post.service";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ContactUsComponent } from "../contact-us/contact-us.component";

@Component({
  selector: "app-sidebar",
  templateUrl: "./vertical-menu.component.html",
  animations: customAnimations
})
export class VerticalMenuComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('toggleIcon') toggleIcon: ElementRef;
  public menuItems: any[];
  level: number = 0;
  logoUrl = 'assets/mindset/logo.png';
  public config: any = {};
  protected innerWidth: any;
  layoutSub: Subscription;
  configSub: Subscription;
  perfectScrollbarEnable = true;
  collapseSidebar = false;
  resizeTimeout;
  type: any;

  contactUsCount = 0;
  inquiryCount = 0;

  constructor(
    private router: Router,
    public translate: TranslateService,
    private layoutService: LayoutService,
    private configService: ConfigService,
    private cdr: ChangeDetectorRef,
    private deviceService: DeviceDetectorService,
    private authservice: AuthService,
    private inquiryService: InquiryService,
    private PollPostService: PollPostService,
    private ModalService: NgbModal

  ) {
    this.config = this.configService.templateConf;
    this.innerWidth = window.innerWidth;
    this.isTouchDevice();
  }

  insertBadge(title, count) {

    for (let config in this.menuItems) {
      if (this.menuItems[config].title === 'Community' || this.menuItems[config].title === 'Communication' || this.menuItems[config].title === 'Creator Management' || this.menuItems[config].title === 'Speakers & Creators' || this.menuItems[config].title === 'Audio') {
        
        if (this.menuItems[config].title == title) {
          this.menuItems[config].badge = count;
          this.menuItems[config].badgeClass = 'badge badge-pill badge-danger float-right  mt-1 mr-2';
        }
        for (let submenu of this.menuItems[config].submenu) {
          if (submenu.title == title) {
            submenu.badge = count;
            submenu.badgeClass = 'badge badgefinal badge-pill badge-danger float-right  mt-1 mr-0';

          }
        }
      }
    }
   
    
  }


  opencontactUs() {
    const modalref = this.ModalService.open(ContactUsComponent, {
      size: 'lg'
    });
  }
  ngOnInit() {
    this.authservice.currentUser$.subscribe((user) => {
      if (this.authservice.currentUserValue) {

        this.type = this.authservice.currentUserValue.userType;
        this.menuItems = ROUTES;
        if (this.authservice.isAdminCategory()) {
          this.menuItems = this.menuItems.filter(m => {
            return m.title != "Contact Us";
          })
          for (let config of this.menuItems) {
            if (config.title === 'Communication') {
              config.badge = "";
              break;
            }
          }

          this.pollpostcount();
          this.creatorAccountRequest();
          this.appcontactUsCount();
          this.speakerCount();
          if(this.authservice.isCRUDAdmin()){
            this.audioListCount();
          }
          // this.contactUsService.getBadgeCount();
          // this.contactUsService.badgeCount$.subscribe((count) => {
          //   this.contactUsCount = count;
          //   this.insertBadge('Contact Us', count);
          //   this.cdr.markForCheck();
          // })

          // this.inquiryService.getBadgeCount();
          // this.inquiryService.badgeCount$.subscribe((count) => {
          //   this.inquiryCount = count;
          //   this.insertBadge('Inquiry List', count)
          //   this.cdr.markForCheck();
          // })
        } else {
          this.menuItems = this.menuItems.filter(m => {
            return m.title != "Communication";
          })
        }
        this.cdr.markForCheck();
      }
    })
  }

  
  audioListCount() {

    this.PollPostService.audioListCount();
    this.PollPostService.moderationCount$.subscribe((res: any) => {
      this.insertBadge('Audio List', res)
      this.insertBadge('Audio', res)
      this.cdr.markForCheck();
    })
  }
  speakerCount() {

    this.PollPostService.speakerCount();
    this.PollPostService.artistCount$.subscribe((res: any) => {
      this.insertBadge('Speakers & Creators', res)
      this.cdr.markForCheck();
    })
  }

  creatorAccountRequest() {
    this.PollPostService.getcreatorAccountRequest();
    this.PollPostService.creatorAccountCount$.subscribe((res: any) => {
      this.insertBadge('Creator Management', res ?? 0)
      this.insertBadge('Creator Account Requests', res ?? 0)
      this.cdr.markForCheck();
    })
  }
  pollpostcount() {
    this.PollPostService.getcommentpostcount();
    this.PollPostService.pollpostCount$.subscribe((res: any) => {
      this.insertBadge('Poll/Post', res.pollPostCount)
      this.insertBadge('Comment Moderation', res.commentCount)
      this.insertBadge('Community', res.commentCount + res.pollPostCount)
      this.cdr.markForCheck();
    })
  }

  appcontactUsCount() {
    this.PollPostService.getcontactUsCount();
    this.PollPostService.contactUsCount$.subscribe((res: any) => {
      this.insertBadge('App - Contact Us', res)
      this.insertBadge('Communication', res)

      // this.insertBadge('Comment Moderation', res.commentCount)
      //this.insertBadge('Community', res.commentCount + res.pollPostCount)
      this.cdr.markForCheck();
    })
  }

  ngAfterViewInit() {

    this.configSub = this.configService.templateConf$.subscribe((templateConf) => {
      if (templateConf) {
        this.config = templateConf;
      }
      this.loadLayout();
      this.cdr.markForCheck();

    });

    this.layoutSub = this.layoutService.overlaySidebarToggle$.subscribe(
      collapse => {
        if (this.config.layout.menuPosition === "Side") {
          this.collapseSidebar = collapse;
        }
      });

  }


  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
    if (this.resizeTimeout) {
      clearTimeout(this.resizeTimeout);
    }
    this.resizeTimeout = setTimeout((() => {
      this.innerWidth = event.target.innerWidth;
      this.loadLayout();
    }).bind(this), 500);
  }

  loadLayout() {

    if (this.config.layout.menuPosition === "Top") { // Horizontal Menu
      if (this.innerWidth < 1200) { // Screen size < 1200
        this.menuItems = HROUTES;
      }
    }
    else if (this.config.layout.menuPosition === "Side") { // Vertical Menu{
      this.menuItems = ROUTES;
    }




    if (this.config.layout.sidebar.backgroundColor === 'white') {
      this.logoUrl = 'assets/img/logo-dark.png';
    }
    else {
      this.logoUrl = 'assets/mindset/logo.png';
    }

    if (this.config.layout.sidebar.collapsed) {
      this.collapseSidebar = true;
    }
    else {
      this.collapseSidebar = false;
    }
  }

  toggleSidebar() {
    let conf = this.config;
    conf.layout.sidebar.collapsed = !this.config.layout.sidebar.collapsed;
    this.configService.applyTemplateConfigChange({ layout: conf.layout });

    setTimeout(() => {
      this.fireRefreshEventOnWindow();
    }, 300);
  }

  fireRefreshEventOnWindow = function () {
    const evt = document.createEvent("HTMLEvents");
    evt.initEvent("resize", true, false);
    window.dispatchEvent(evt);
  };

  CloseSidebar() {
    this.layoutService.toggleSidebarSmallScreen(false);
  }

  isTouchDevice() {

    const isMobile = this.deviceService.isMobile();
    const isTablet = this.deviceService.isTablet();

    if (isMobile || isTablet) {
      this.perfectScrollbarEnable = false;
    }
    else {
      this.perfectScrollbarEnable = true;
    }

  }


  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.configSub) {
      this.configSub.unsubscribe();
    }

  }

}
