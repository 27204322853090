import { ChangeDetectorRef, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CreatorProfileService } from 'app/modules/creator-profile/_service/creator-profile.service';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { first } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class UnderPreviewGuard implements CanActivate {

  constructor(private authService: AuthService, private route: Router, private toaster: ToastrService, private creatorProfileService: CreatorProfileService) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const type = this.authService.currentUserValue?.userType;
    if (type == 2) {
      const userType = this.authService.currentUserValueType;
      if (!userType) {
        this.creatorProfileService.getDetail().pipe(first())
          .subscribe((x: any) => {


            this.authService.currentUserTypeSubject.next(x.result)
          });
      }
      
      if (userType && userType.status == "underReview" && !userType.isClaimArtist) {
        this.toaster.error('Your Account is Under Review.', "error", {
          positionClass: 'toast-bottom-right',
        })
        this.route.navigate(['/profile']);
      }

    } 
    return true;

  }

}
