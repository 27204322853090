import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContactUsService {

  private apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }
  private _isLoadingpollpostSetting$ = new BehaviorSubject<boolean>(false);
  private _isLoadingPollPost$ = new BehaviorSubject<boolean>(false);

  get isLoadingpollpostSetting$() {
    return this._isLoadingpollpostSetting$.asObservable();
  }

  SendMailUser(requestBody) {
    this._isLoadingPollPost$.next(true);
    return this.http.post(`${this.apiUrl}/creator/contact/us`, requestBody).pipe(
      finalize(() => {
        this._isLoadingPollPost$.next(false)
      })
    );
  }
}
