import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { BehaviorSubject } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class RightHoldersService {
  private _isLoading$ = new BehaviorSubject<boolean>(false);
  private _isLoadingForm$ = new BehaviorSubject<boolean>(false);
  private _isLoadingtable$ = new BehaviorSubject<boolean>(false);

  private apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) { }

  get isLoading$() {
    return this._isLoading$.asObservable();
  }

  get isLoadingtable$() {
    return this._isLoadingtable$.asObservable();
  }

  get isLoadingForm$() {
    return this._isLoadingForm$.asObservable();
  }



  create(admin: any) {
    this._isLoading$.next(true);
    return this.http.post(`${this.apiUrl}/rights/holders/add`, admin).pipe(
      finalize(() => {
        this._isLoading$.next(false)
      })
    );
  }

  getall(params) {
    this._isLoadingtable$.next(true);
    return this.http.post(`${this.apiUrl}/rights/holders/`, params).pipe(
      finalize(() => {
        this._isLoadingtable$.next(false)
      })
    );
  }

  getallrightholder() {
    this._isLoadingtable$.next(true);
    return this.http.post(`${this.apiUrl}/general/account`, { type: 4 }).pipe(
      finalize(() => {
        this._isLoadingtable$.next(false)
      })
    );
  }


  delete(id) {
    return this.http.put(`${this.apiUrl}/rights/holders/change-status/${id}`, { status: 3 });
    // return this.http.delete(`${this.apiUrl}/user/${id}`);
  }

  delete_pdf(req) {
    
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: req
    };
    return this.http.delete(`${this.apiUrl}/rights/holders/file`, options);
    
  }

  changestatus(id, status) {
    return this.http.put(`${this.apiUrl}/rights/holders/change-status/${id}`, { status });
  }

  getById(id) {
    return this.http.patch(`${this.apiUrl}/rights/holders/detail/${id}`, {});
  }

  update(admin: any, id) {
    this._isLoading$.next(true);
    return this.http.put(`${this.apiUrl}/rights/holders/${id}`, admin).pipe(
      finalize(() => {
        this._isLoading$.next(false)
      })
    );
  }

  currencyForm(body: any) {
    this._isLoadingForm$.next(true);
    return this.http.post(`${this.apiUrl}/payment/history/wise/dynamic/form`, body).pipe(
      finalize(() => {
        this._isLoadingForm$.next(false)
      })
    );
  }

  addPayment(body: any) {
    this._isLoadingForm$.next(true);
    return this.http.post(`${this.apiUrl}/payment/history/wise/account/create`, body).pipe(
      finalize(() => {
        this._isLoadingForm$.next(false)
      })
    );
  }
  
}
