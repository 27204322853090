import { RouteInfo } from "./vertical-menu.metadata";
import { Roles } from 'app/roles';

const { ADMIN, MANAGER, CREATOR, RIGHT_HOLDER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED } = Roles;

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
  {
    path: "/dashboard",
    title: "Dashboard",
    icon: "ft-home",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
    hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED, RIGHT_HOLDER],
  },

  {
    path: "/profile",
    title: "Profile",
    icon: "ft-user",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
    hasaccess: [CREATOR],
  },
  {
    path: "/admins",
    title: "Admin",
    icon: "ft-user",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
    hasaccess: [ADMIN],
  },
  {
    path: "/breakup-row/list",
    title: "Break-up Rows",
    icon: "ft-align-justify",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
    hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
  },
  {
    path: "",
    title: "Settings",
    icon: "ft-settings",
    class: "has-sub",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [
      {
        path: "/categories",
        title: "Categories",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
      },
      {
        path: "/energy-level",
        title: "Energy Level",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
      },
      {
        path: "/search-settings",
        title: "Search Settings",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
      },
      {
        path: "/onboarding-bucket",
        title: "Onboarding Buckets",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
      },
      {
        path: "/settings",
        title: "Settings",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
      },
      {
        path: "",
        title: "Suggested Artists",
        icon: "submenu-icon",
        class: "has-sub",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [{
          path: "/suggested/universally",
          title: "Suggested Universally",
          icon: "ft-arrow-right submenu-icon",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
          hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
        },
        {
          path: "/suggested/suggested-by-category",
          title: "Suggested By Category",
          icon: "ft-arrow-right submenu-icon",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
          hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
        },
        {
          path: "/suggested/suggested-by-energy",
          title: "Suggested By Energy",
          icon: "ft-arrow-right submenu-icon",
          class: "",
          badge: "",
          badgeClass: "",
          isExternalLink: false,
          submenu: [],
          hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
        }],
        hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
      },
    ],
    hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
  },
  {
    path: "/artists",
    title: "Speakers & Creators",
    icon: "ft-mic",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
    hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
  },


  {
    path: "",
    title: "Content",
    icon: "ft-music",
    class: "has-sub",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [{
      path: "/audios",
      title: "Audio List",
      icon: "ft-arrow-right submenu-icon",
      class: "",
      badge: "",
      badgeClass: "",
      isExternalLink: false,
      submenu: [],
      hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED, CREATOR],
    }, {
      path: "/videos",
      title: "Video List",
      icon: "ft-arrow-right submenu-icon",
      class: "",
      badge: "",
      badgeClass: "",
      isExternalLink: false,
      submenu: [],
      hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
    }, {
      path: "/mindset-scheduler",
      title: "Daily Mindset Scheduler",
      icon: "ft-arrow-right submenu-icon",
      class: "",
      badge: "",
      badgeClass: "",
      isExternalLink: false,
      submenu: [],
      hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
    }, {
      path: "/downloaded-audios",
      title: "Auto-synched Downloads",
      icon: "ft-arrow-right submenu-icon",
      class: "",
      badge: "",
      badgeClass: "",
      isExternalLink: false,
      submenu: [],
      hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED, CREATOR],
    }],
    hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED, CREATOR],
  },

  {
    path: "",
    title: "Community",
    icon: "ft-users",
    class: "has-sub",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
    submenu: [
      {
        path: "/community/schedule-poll-post",
        title: "Schedule Poll/Post",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
      },
      {
        path: "/community/poll-post",
        title: "Poll/Post",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
      },
      {
        path: "/community/poll-post-setting",
        title: "Poll/Post Settings",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
      },

      {
        path: "/community/comment-moderation",
        title: "Comment Moderation",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
      },

      {
        path: "/community/moderation-settings",
        title: "Moderation Settings",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
      },



    ],
  },
  {
    path: "",
    title: "Playlist",
    icon: "ft-music",
    class: "has-sub",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
    submenu: [
      {
        path: "/playlist/list",
        title: "Playlist",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
      },

      {
        path: "/playlist/featured-playlist",
        title: "Playlist App Tab",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
      },
    ],
  },

  {
    path: "/users",
    title: "Users",
    icon: "ft-users",
    class: "has-sub",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [{
      path: "/users/user-list",
      title: "Users List",
      icon: "ft-arrow-right submenu-icon",
      class: "",
      badge: "",
      badgeClass: "",
      isExternalLink: false,
      submenu: [],
      hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
    }, {
      path: "/users/claim-user-list",
      title: "Claim Username List",
      icon: "ft-arrow-right submenu-icon",
      class: "",
      badge: "",
      badgeClass: "",
      isExternalLink: false,
      submenu: [],
      hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
    }],
    hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
  },
  {
    path: "/users",
    title: "Creator Management",
    icon: "ft-user-plus",
    class: "has-sub",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [{
      path: "/creator/creator-userlist",
      title: "Creator Account Requests",
      icon: "ft-arrow-right submenu-icon",
      class: "",
      badge: "",
      badgeClass: "",
      isExternalLink: false,
      submenu: [],
      hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
    },
    {
      path: "/creator/prospective-creators",
      title: "Prospective Creators & speakers",
      icon: "ft-arrow-right submenu-icon",
      class: "",
      badge: "",
      badgeClass: "",
      isExternalLink: false,
      submenu: [],
      hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
    },
    {
      path: "",
      title: "Partner Share",
      icon: "submenu-icon",
      class: "has-sub",
      badge: "",
      badgeClass: "",
      isExternalLink: false,
      submenu: [{
        path: "/creator/royalty-payment",
        title: "Royalty Payment",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
      },
      {
        path: "/creator/royalty-payment-history",
        title: "Royalty Payment History",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
      }],
      hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,]
    }

    ],
    hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
  },
  {
    path: "",
    title: "Communication",
    icon: "ft-mail",
    class: "has-sub",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [
      {
        path: "",
        title: "Notification",
        icon: "ft-arrow-right submenu-icon",
        class: "has-sub",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [{
          path: "communication/broadcast-notification/broadcast",
          title: "Broadcast Notification",
          icon: "ft-arrow-right submenu-icon",
          class: "",
          badge: '', badgeClass: '',
          isExternalLink: false,
          submenu: [],
          hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
        }, {
          path: "communication/alarm-notification",
          title: "Alarm Notification",
          icon: "ft-arrow-right submenu-icon",
          class: "",
          badge: '', badgeClass: '',
          isExternalLink: false,
          submenu: [],
          hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
        }, {
          path: "communication/popup-management",
          title: "Popup Management",
          icon: "ft-arrow-right submenu-icon",
          class: "",
          badge: '', badgeClass: '',
          isExternalLink: false,
          submenu: [],
          hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
        },

        {
          path: "communication/retention",
          title: "Retention Notification",
          icon: "ft-arrow-right submenu-icon",
          class: "",
          badge: '', badgeClass: '',
          isExternalLink: false,
          submenu: [],
          hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
        },],
        hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
      },
      {
        path: "",
        title: "Comment",
        icon: "ft-arrow-right submenu-icon",
        class: "has-sub",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [{
          path: "communication/comment-list",
          title: "Comment List",
          icon: "ft-arrow-right submenu-icon",
          class: "",
          badge: '', badgeClass: '',
          isExternalLink: false,
          submenu: [],
          hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
        }, {
          path: "communication/comment-report-list",
          title: "Comment Report List",
          icon: "ft-arrow-right submenu-icon",
          class: "",
          badge: '', badgeClass: '',
          isExternalLink: false,
          submenu: [],
          hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
        },],
        hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
      },
      {
        path: "communication/bugs",
        title: "Bug List",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: '', badgeClass: '',
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
      },

      {
        path: "communication/contact-us",
        title: "App - Contact Us",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: '', badgeClass: '',
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
      },
      {

        path: "/pop-up/list",
        title: "In-app Popup",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
      },
      {
        path: "communication/creator-contact-us",
        title: "Creator - Contact Us",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: '', badgeClass: '',
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
      },


    ],
    hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
  },
  {
    path: "",
    title: "Hashtags",
    icon: "ft-hash",
    class: "has-sub",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [
      {
        path: "/hashtags/hashtag-list",
        title: "Hashtag List",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
      },
      {
        path: "/hashtags/restrict-hashtag",
        title: "Restrict Hashtags List",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
      },
    ],
    hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
  },
  {
    path: "",
    title: "Ads Management",
    icon: "fa fa-adn",
    class: "has-sub",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [
      {
        path: "ads-management/setting",
        title: "Ads Settings",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
      },
      {
        path: "ads-management/list",
        title: "Ads Management",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
      },
    ],
    hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
  },
  {
    path: "creator-analytics",
    title: "Analytics",
    icon: "fa fa-bar-chart",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
    hasaccess: [CREATOR],
  },
  {
    path: "",
    title: "Contact Us",
    icon: "fa fa-envelope",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
    hasaccess: [CREATOR],
  },

  {
    path: "https://www.mindsetapp.com/faqs-for-partners",
    title: "FAQ",
    icon: "fa fa-question-circle",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: true,
    submenu: [],
    hasaccess: [CREATOR],
  },

];
