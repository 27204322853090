<div class="modal-header">
    <h4 class="modal-title">Contact Us</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.close()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body">
    <form [formGroup]="sendForm" (submit)="send()">
        <div class="row">
            <div class="form-group col-md-12">
                <ul class="errorsclass" *ngIf="finalerror.length > 0">
                    <li *ngFor="let error of finalerror">{{ error }}</li>
                </ul>
            </div>
            <div class="form-group col-lg-12">
                <label for="nf-dailyAudio">Email*</label>
                <input type="text" formControlName="email" placeholder="Email" class="form-control">
                <div class="text-danger" *ngIf="submittedsendform && sendForm.get('email').errors && sendForm.get('email').hasError('required')
                      ">
                    <span class="errormessage">Email is required</span>
                </div>
            </div>
            <div class="form-group col-lg-12">
                <label for="nf-dailyAudio">Subject*</label>
                <input type="text" formControlName="subject" placeholder="Subject" class="form-control">
                <div class="text-danger" *ngIf="submittedsendform && sendForm.get('subject').errors && sendForm.get('subject').hasError('required')
                      ">
                    <span class="errormessage">Subject is required</span>
                </div>
            </div>
            <div class="form-group col-lg-12">
                <label for="nf-dailyAudio">Message*</label>
                <textarea formControlName="message" class="form-control" placeholder="Message"></textarea>
                <div class="text-danger" *ngIf="submittedsendform && sendForm.get('message').errors && sendForm.get('message').hasError('required')
                      ">
                    <span class="errormessage">Message is required.</span>
                </div>
            </div>
            <div class="form-group col-lg-12">
                <button class="btn btn-sm btn-primary" type="submit"><i class="fa fa-paper-plane"></i> Send <i
                        class="fa fa-spinner fa-spin" *ngIf="isLoadingPollPost$ | async"></i></button>
            </div>
        </div>
    </form>

</div>