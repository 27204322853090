// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  //apiUrl: "http://localhost:4000/api",
  //apiUrl: "https://admin.mindsetapp.com/api",
  apiUrl : 'https://admin.stage.mindsetapp.com/api',
  googleauth: {
    client_id:
      "707236634950-5josa9f31pb1ikoqln3botuc4r3siper.apps.googleusercontent.com",
    client_secret: "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",
    scope: "https://www.googleapis.com/auth/youtube",
  },
  recaptcha: {
    siteKey: "6LeBoX0dAAAAALnvJCQUcwHFnp6jBz0nTQaGsYrC",
  },
  FacebookAppID: '920618128394401',
  GoogleAuthKey: '810953015846-g0l423fo2n9m59uufqo9m1nmav24d95h.apps.googleusercontent.com',
};
